import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = [
    "main_form",
    "disable_btn",
    "enable_btn"
  ]

  onPostPutSuccess(event) {
    const content_target = event.currentTarget.closest(".services_load")
    let controller = this.tabLoadController(content_target)
    const [data, status, xhr] = event.detail;
    if (data) {
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      controller.reload(content_target);
    }
  }

  tabLoadController(element) {
    return this.application.getControllerForElementAndIdentifier(element, "layout--load")
  }

}